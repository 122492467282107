import GoToTop from "../../components/GoToTop/GoToTop";
import UsabilityTesting from "../../assets/images/AAIFF_usability_testing.png";
import CompetitiveAnalysis from "../../assets/images/AAIFF_competitive_analysis.png";
import CurrentWebsite from "../../assets/images/AAIFF_current_website.png";
import UserFlow from "../../assets/images/AAIFF_user_flow.jpg";
import Plan from "../../assets/images/AAIFF_plan.png";
import DesignProposal from "../../assets/images/AAIFF_design_proposal.png";
import MultiDevice from "../../assets/images/Device_Mashups.png";
import MobileThumbnail from "../../assets/images/AAIFF_high_fidelity_wireframes.png";
import MainThumbnail from "../../assets/images/AAIFF_main_thumbnail.png";
import Video from "../../assets/videos/AAIFF_Mobile_Prototype.mov";
import Impact from "../../assets/images/AAIFF_impact.png";

let AAIFF = () => {
  return (
    <main className="main-container">
      <section className="main projects">
        <span className="feature__title">
          <h1 className="feature__title__text">ASIAN CINEVISION</h1>
          <div className="feature__title__line" />
        </span>{" "}
        <img src={MainThumbnail} className="projects__hero" alt="hero" />
        <a
          href="https://www.aaiff.org/program"
          target="_blank"
          rel="noreferrer"
          className="keki-category__link"
        >
          View the design live at AAIFF's website
        </a>
        <h5 className="projects__subheader">Project Overview</h5>
        <article className="keki-client">
          <h4 className="keki-client__title">PRODUCT</h4>
          <p className="keki-category__text">
            The Asian American International Film Festival is the nation's first
            and longest-running festival of its kind established in 1978 by
            Asian Cinevision. I joined the web team to help improve the user
            experience ahead of this years's festival launch, contributing to
            both UX design and web development.
          </p>
        </article>
        <article className="keki-statement">
          <div className="keki-statement__subcat">
            <h4 className="keki-statement__subcat__title">PROBLEM</h4>
            <p className="keki-statement__subcat__text">
              Users find it confusing to purchase tickets online, leading to
              wrong tickets being bought, high numbers of customer emails, and
              revenue loss for the organization.
            </p>
            <p className="keki-statement__subcat__text">
              Our assumptions are that this is due to the{" "}
              <strong style={{ backgroundColor: "#dbefbc" }}>
                unclear information
              </strong>{" "}
              and busy design of the current website. The ticketing page
              contains language that is not clear to everyone, leading to{" "}
              <strong style={{ backgroundColor: "#dbefbc" }}>
                poor accessibility
              </strong>
              . The FAQs page is too busy and overwhelming, making it{" "}
              <strong style={{ backgroundColor: "#dbefbc" }}>
                hard to navigate
              </strong>
              . In addition, Elevent, the third-party ticketing system, provides
              limited customization, preventing a smooth user flow.
            </p>
          </div>
          <div className="keki-statement__subcat">
            <h4 className="keki-statement__subcat__title">GOAL</h4>
            <p className="keki-statement__subcat__text">
              The main objective is to make the ticketing page more
              user-friendly by providing clear information about the different
              types of tickets and what each of the passes include. Aditionally,
              creating a simple ticket purchasing flow with clear instructions
              would allow the user to seamlessly complete their task.
            </p>
            <p className="keki-statement__subcat__text">
              The success of this project will be measured by the following
              <strong style={{ backgroundColor: "#dbefbc" }}> KPIs</strong>:
            </p>
            <ul className="keki-list">
              <li>Increased ticket sales revenue</li>
              <li>Reduced number of customer support emails</li>
            </ul>
          </div>
        </article>
        <article className="keki-details">
          <div className="keki-details__subcat__team">
            <h4 className="keki-details__subcat__title">TEAM</h4>
            <p className="keki-details__subcat__text">
              My role was UX designer from conception to delivery in
              collaboration with fellow UX designer Lauren Chun. Our team of 11
              included a UX lead, web developers, and a project manager.
            </p>
          </div>
          <div className="keki-details__subcat">
            <h4 className="keki-details__subcat__title">TOOLS</h4>
            <ul className="keki-list">
              <li>Figma</li>
              <li>Google Suite</li>
            </ul>
          </div>
          <div className="keki-details__subcat">
            <h4 className="keki-details__subcat__title">
              PROJECT
              <br />
              DURATION
            </h4>
            <p>April 2024 - June 2024</p>
          </div>
        </article>
        <article className="keki-impact">
          <h4 className="keki-research__title">IMPACT</h4>
          <img
            src={Impact}
            className="keki-category__image"
            alt="impact metrics"
          ></img>
        </article>
        <h5 className="projects__subheader">Understanding the User</h5>
        <article className="keki-research">
          <h4 className="keki-research__title">RESEARCH GOALS</h4>
          <div className="keki-research__content">
            <p className="keki-research__summary">
              Based on the information provided by the stakeholders and after
              evaluating the ticket purchasing flow, we decided to conduct a
              round of usability testing of the current website that would give
              us a better understanding of the specific user pain points. <br />
              <br />
              The objective of this research phase was to{" "}
              <strong>validate our initial assumptions</strong> as explained on
              the problem statement.
            </p>
            <div className="keki-research__summary">
              <p>These are the subjects we focused on during testing:</p>
              <ul className="keki-list">
                <li>
                  <strong>User understanding</strong> of the ticket purchase
                  flow and their <strong>behavior</strong> when deciding which
                  ticket to buy
                </li>
                <li>
                  <strong>Ticket types and pricing confusion</strong>
                </li>
                <li>
                  <strong>Usability and navigation</strong> of the current
                  interface, if it effectively communicates information about
                  ticket options and event details and identify any design
                  elements or features that contribute to user confusion
                </li>
                <li>
                  <strong>Information accessibility and clarity</strong>
                </li>
                <li>
                  <strong>Mobile responsiveness</strong>
                </li>
              </ul>
            </div>
          </div>
          <h4 className="keki-research__title">USABILITY TESTING RESULTS</h4>
          <img
            src={UsabilityTesting}
            className="keki-research__image"
            alt="usability testing"
          />
          <h4 className="keki-research__title">UNEXPECTED FINDINGS</h4>
          <div className="keki-research__content">
            <p className="keki-research__summary">
              The results confirmed our initial assumptions, but most
              importantly, brought to light pain points we hadn't anticipated,
              <strong> increasing the scope</strong> of our project and urging
              us to reconsider our original plan. These issues involved the main
              festival program page and the individual event pages, which were
              still part of the ticket purchasing user flow. Below are detailed
              notes on the findings.
            </p>
          </div>
          <h4 className="keki-research__title">
            CURRENT WEBSITE WITH NOTES ON USER PAIN POINTS
          </h4>
          <img
            src={CurrentWebsite}
            className="keki-research__image"
            alt="usability testing"
          />
          <h4 className="keki-research__title">COMPETITIVE ANALYSIS</h4>
          <p className="keki-research__analysis">
            While working on the usability testing, we conducted a competitive
            analysis of other film events in the market to further inform our
            process. After some initial research, we chose to focus on these
            specific ones because their professionally designed websites
            enhanced their credibility. It was interesting to learn how they
            each package and explain the benefits included in their tickets and
            passes and the different user flows for purchasing them.
          </p>
          <img
            src={CompetitiveAnalysis}
            className="keki-research__image"
            alt="Competitive analysis"
          />
        </article>
        <article className="keki-category">
          <h4 className="keki-category__title">USER FLOW</h4>
          <p className="keki-category__text">
            After some iterations informed by our research, we created a simple
            user flow including a clean filtering section and informative yet
            concise ticketing options that would potentially motivate the user
            to buy a pass over an individual ticket for full festival access.
          </p>
          <img
            src={UserFlow}
            className="keki-category__image__padding"
            alt="user flow"
          ></img>
        </article>
        <h5 className="projects__subheader">Starting the Design</h5>
        <article className="keki-category">
          <h4 className="keki-category__title">PLANNING & SCOPE</h4>
          <div className="keki-plan">
            <p className="keki-plan__text">
              Before diving into design, we{" "}
              <strong>redefined the problem statement</strong> to include the
              following research findings:
              <ul className="keki-list">
                <li>
                  The festival program page was{" "}
                  <strong style={{ backgroundColor: "#dbefbc" }}>
                    too busy and hard to navigate
                  </strong>{" "}
                  and features such as the category cards at the top of the page
                  were distracting.
                </li>
                <li>
                  <strong style={{ backgroundColor: "#dbefbc" }}>
                    Language is not accessible
                  </strong>{" "}
                  across multiple pages. Industry terminology should be
                  explained or replaced.
                </li>
                <li>
                  Users were not clear about what made an event different from a
                  regular screening, consequently, they weren't interested in
                  paying a higher price for the corresponding ticket.
                </li>
                <li>
                  Key information such as time and venue was not easiliy
                  available when browsing the program page.
                </li>
              </ul>
            </p>
            <p className="keki-plan__text">
              We decided to scope out the project based on the level of effort
              and impact each task would have considering our tight deadline. We
              divided the tasks, so from here on, Lauren focused on the
              Ticketing and FAQs pages and navigation bar, while I focused on
              the Festival Program and Event Detail pages.
            </p>
          </div>
          <img
            src={Plan}
            className="keki-category__image"
            alt="pain point and opportunities"
          ></img>
          <h4 className="keki-category__title">MAIN DESIGN FEATURES</h4>

          <img
            src={DesignProposal}
            className="keki-category__image"
            alt="pain point and opportunities"
          ></img>
        </article>
        <h5 className="projects__subheader">Refining the Design</h5>
        <p className="keki-category__text">
          After some review sessions and feedback, I went back to make updates
          and finalize the responsive designs, adhering to the organization's
          Design System and solving for different edge cases due to differences
          in event structuring and information available.
        </p>
        <img
          src={MobileThumbnail}
          className="keki-category__image"
          alt="hi fi proto"
        ></img>
        <img
          src={MultiDevice}
          className="keki-category__image"
          alt="mockups"
        ></img>
        <span className="keki-movie">
          <h4 className="keki-category__title">FINAL PROTOTYPE</h4>
          <video
            loop
            autoPlay
            muted
            src={Video}
            type="video/mp4"
            className="keki-movie__clip"
          ></video>
        </span>
        <h5 className="projects__subheader">Going Forward</h5>
        <article className="keki-forward">
          <div className="keki-category keki-forward__content">
            <h4 className="keki-category__title">CHALLENGES</h4>
            <ul className="keki-list">
              <li>
                One of the biggest challenges was to get a full understanding of
                the user pain points because of the different types of events
                included in the festival, which determined the type of ticket
                involved.
              </li>
              <li>
                Understanding the differences between the ticket options and the
                functionality and limitations of the ticketing third-party
                application Elevent required connecting with other stakeholders
                within the organization.
              </li>
              <li>
                Deciding on which opportunities to address and which were out of
                scope helped us address the unexpected and define the project as
                we moved on to designing.
              </li>
            </ul>
          </div>
          <div className="keki-category  keki-forward__content">
            <h4 className="keki-category__title">WHAT I LEARNED</h4>
            <p className="keki-category__text">
              Collaboration played a key role in this project. Working with
              Lauren on the first half of the project while conducting research
              and later dividing up the design tasks proved to be an efficient
              strategy that allowed us to present data-driven design proposals
              within the designated time frame.
            </p>
            <br /> <br />
            <h4 className="keki-category__title">NEXT STEPS</h4>
            <ul className="keki-list">
              <li>
                Conduct a round of usability testing on the updated design to
                verify it effectively addresses the established user pain
                points.
              </li>
              <li>
                Analyze the quantity and content of customer emails received
                during this year's festival to determine if users were having
                trouble at any point of the ticket purchasing flow.
              </li>
            </ul>
          </div>
        </article>
      </section>
      <GoToTop />
    </main>
  );
};

export default AAIFF;
