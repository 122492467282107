import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "../src/components/Header/Header";
import Homepage from "../src/pages/Homepage/Homepage";
import MyWork from "../src/pages/WebApps/WebApps";
import BotanicHub from "../src/pages/BotanicHub/BotanicHub";
import AtelierKeki from "../src/pages/AtelierKeki/AtelierKeki";
import Moviepop from "./pages/Moviepop/Moviepop";
import AAIFF from "./pages/AAIFF/AAIFF";
import Apparel from "../src/pages/Apparel/Apparel";
import Transcendent from "../src/pages/Tdream/Tdream";
import ShapeActive from "../src/pages/ShapeActive/ShapeActive";
import UnderArmour from "../src/pages/UnderArmour/UnderArmour";
import Process from "../src/pages/Process/Process";
import About from "../src/pages/About/About";
import Footer from "../src/components/Footer/Footer";
import BackToTopButton from "./components/ScrollTop/BackToTopButton";
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize("G-002DZKZK4B");

  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/my-work" element={<MyWork />} />
          <Route path="/botanic-hub" element={<BotanicHub />} />
          <Route path="/atelier-keki" element={<AtelierKeki />} />
          <Route path="/moviepop" element={<Moviepop />} />
          <Route path="asian-cinevision" element={<AAIFF />} />
          <Route path="/fashion" element={<Apparel />} />
          <Route path="/transcendent" element={<Transcendent />} />
          <Route path="/shapeactive" element={<ShapeActive />} />
          <Route path="/underarmour" element={<UnderArmour />} />
          <Route path="/process" element={<Process />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </Router>
      <BackToTopButton />
    </div>
  );
}

export default App;
