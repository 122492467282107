import shape1 from "../../assets/images/shape-1.jpeg";
import shape2 from "../../assets/images/shape-2.jpeg";
import shape3 from "../../assets/images/shape-3.jpeg";
import shape4 from "../../assets/images/shape-4.jpeg";
import shape5 from "../../assets/images/shape-5.jpeg";
import shape6 from "../../assets/images/shape-6.jpeg";
import shape7 from "../../assets/images/shape-7.jpeg";
import GoToTop from "../../components/GoToTop/GoToTop";

let ShapeActive = () => {
  return (
    <main className="main-container">
      <section className="main">
        <span className="feature__title">
          <h1 className="feature__title__text">SHAPE ACTIVE</h1>
          <div className="feature__title__line" />
        </span>
        <img src={shape1} className="apparel" />
        <img src={shape2} className="apparel" />
        <img src={shape3} className="apparel" />
        <img src={shape4} className="apparel" />
        <img src={shape5} className="apparel" />
        <img src={shape6} className="apparel" />
        <img src={shape7} className="apparel" />
      </section>
      <GoToTop />
    </main>
  );
};

export default ShapeActive;
