import "./Footer.scss";
import GithubLogo from "../GithubLogo/GithubLogo";
import LinkedinLogo from "../LinkedinLogo/LinkedinLogo";
import EmailIcon from "../EmailIcon/EmailIcon";

let Footer = () => {
  return (
    <section className="footer-container">
      <div className="footer">
        <span className="footer__links">
          <a
            href="https://github.com/mieko61/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="footer__links__item">
              <GithubLogo height="2rem" />
            </div>
          </a>
          <a
            href="https://www.linkedin.com/in/mieko-tominaga/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="footer__links__item">
              <LinkedinLogo height="2rem" />
            </div>
          </a>
          <a
            href="mailto:miekotominaga@gmail.com"
            target="_blank"
            className="about__text__link"
            rel="noreferrer"
          >
            <div className="footer__links__item">
              <EmailIcon height="2rem" />
            </div>
          </a>
        </span>
        <p className="footer__text">
          This website was designed using <strong>Figma</strong>. Coded in{" "}
          <strong>Visual Studio Code</strong> and built with{" "}
          <strong>ReactJS</strong> and <strong>Sass</strong>. Deployed with{" "}
          <strong>Netlify</strong>.
        </p>
      </div>
    </section>
  );
};

export default Footer;
