import "./About.scss";
import savPhoto from "../../assets/images/sav-photo.jpeg";
import resume from "../../assets/images/Mieko_Tominaga_Resume.jpg";

let About = () => {
  return (
    <main className="main-container">
      <section className="main about">
        <img src={savPhoto} className="about__photo" alt="person" />
        <div className="about__text">
          <p className="about__text__content">
            My name is Mieko and I'm a software engineer & product designer
            based in NYC. Although my career path has taken many turns,{" "}
            <strong style={{ backgroundColor: "#dbefbc" }}>
              creativity and problem-solving
            </strong>{" "}
            are traits that have been present throughout. Having first worked as
            an{" "}
            <strong style={{ backgroundColor: "#f2eafc" }}>
              activewear apparel designer
            </strong>{" "}
            for 6+ years, researching and translating trends and focusing on the
            functional side of design - the user's needs and finding innovative
            solutions were what I enjoyed the most.
          </p>
          <p className="about__text__content">
            Later on, I decided to take a break from fashion to pursue a
            longtime hobby of mine: baking 🍰. I worked as a{" "}
            <strong style={{ backgroundColor: "#f2eafc" }}>pastry chef</strong>{" "}
            for over 4 years and loved it, especially working with my hands and
            being able to get creative with different recipes.
          </p>
          <p className="about__text__content">
            However, I wanted to get back into developing products. Thus, I
            learned{" "}
            <strong style={{ backgroundColor: "#f2eafc" }}>UX design</strong>{" "}
            and helped bring a couple of projects to life. Now, as a{" "}
            <strong style={{ backgroundColor: "#f2eafc" }}>
              software engineer
            </strong>
            , I can make those projects functional and it's been a challenging
            yet rewarding journey. ✨✨
          </p>
          <a
            href={resume}
            target="_blank"
            className="about__text__link"
            rel="noreferrer"
          >
            <button className="about__text__link__button">View resume</button>
          </a>
        </div>
      </section>
    </main>
  );
};

export default About;
