import "./Header.scss";
import { pdfjs } from "react-pdf";
import logo from "../../assets/logos/logo.png";
import DiagonalArrow from "../../assets/icons/diagonal-arrow-right-up-outline-svgrepo-com.svg";
import resume from "../../assets/files/Mieko_Tominaga_Resume_D.pdf";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
pdfjs.GlobalWorkerOptions.workerSrc = `cdnjs.cloudfare.com/ajax/libs.pdf.js/${pdfjs.version}/pdf.worker.js`;

let Header = () => {
  return (
    <header className="header-container">
      <div className="header">
        <NavLink to={"/"}>
          <img src={logo} className="header__logo" alt="logo" />
        </NavLink>
        <div className="header__nav">
          <Link
            to="/#about"
            spy={true}
            smooth={true}
            duration={500}
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <h3>ABOUT</h3>
          </Link>
          <Link
            to="/#my-work"
            spy={true}
            smooth={true}
            duration={500}
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <h3>WORK</h3>
          </Link>
          <a
            href={resume}
            target="_blank"
            rel="noreferrer"
            className="nav-item"
          >
            <h3>RESUME</h3>
            <img src={DiagonalArrow} className="nav-item__link" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
