import "./Homepage.scss";
import hero from "../../assets/images/profile_photo.svg";
import { Link } from "react-router-dom";
import botanichub from "../../assets/images/botanic_hub_mobile-view.svg";
import keki from "../../assets/images/keki-cover.png";
import hydrate from "../../assets/images/hydrate.png";
import moviepop from "../../assets/images/moviepop-cover.jpeg";
import asiancinevision from "../../assets/images/AAIFF_main_thumbnail.png";
import GithubLogo from "../../components/GithubLogo/GithubLogo";
import GlobeLogo from "../../components/GlobeLogo/GlobeLogo";
import { Element } from "react-scroll";
import ReactGA from "react-ga4";

let Homepage = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "Home",
  });

  return (
    <main className="main-container">
      <section className="main">
        <div className="hero">
          <div className="hero__text">
            <h2 className="hero__text__p1">Hello! I'm Mieko, </h2>
            <p className="hero__text__p2">
              a passionate <strong>UX designer</strong> dedicated to crafting
              experiences that enhance people's lives.
            </p>
            <p className="hero__text__p2">
              I leverage my attention to detail and technical expertise to
              transform creative concepts into functional and engaging
              interfaces. ✨
            </p>
            <a
              href="mailto:miekotominaga@gmail.com"
              target="_blank"
              className="about__text__link"
              rel="noreferrer"
            >
              <button className="about__text__link__button">
                Get in touch
              </button>
            </a>
          </div>
          <img src={hero} className="hero__image" alt="self portrait" />
        </div>
        <Element className="story" name="about-element" id="about">
          <h3>ABOUT ME</h3>
          <div className="story__text">
            <p className="about__text__content">
              My career has been driven by creativity and a constant desire for
              growth. I started as an{" "}
              <Link to="/fashion" className="hero__link">
                activewear designer
              </Link>{" "}
              where I focused on creating innovative and functional fashion.
              Known for my organizational skills, I led initiatives that
              streamlined workflows and improved efficiency across teams.
            </p>
            <p className="about__text__content">
              Transitioning to{" "}
              <a href="#my-work" className="hero__link">
                UX design
              </a>{" "}
              allowed me to hone my ability to identify and solve user needs.
              With a strong foundation in coding, I approach collaboration with
              empathy, eager to combine creativity and problem-solving to build
              meaningful, user-centric products. I thrive on learning new
              technologies and embracing new challenges.
            </p>
            <p className="about__text__content">
              In my free time, I love staying active, planning trips, and
              discovering new restaurants.
            </p>
          </div>
        </Element>
        <Element className="feature" name="my-work-element" id="my-work">
          <span className="feature__title">
            <h1 className="feature__title__text">MY WORK</h1>
            <div className="feature__title__line" />
          </span>
          <article className="projects-container">
            <div className="project">
              <Link to="/asian-cinevision" className="project-image">
                <img
                  src={asiancinevision}
                  className="project-image__file"
                  alt="project thumbnail"
                />
              </Link>
              <div className="project-text">
                <Link to="/asian-cinevision" className="project-text__link-ux">
                  <h4>Asian Cinevision</h4>
                </Link>
                <ul className="tech">
                  <li className="tech__item">UX Research</li>
                  <li className="tech__item">UX Design</li>
                  <li className="tech__item">Front-End Web Dev</li>
                </ul>
                <p className="project-text__description">
                  Designed the 2024 Asian American International Film Festival
                  (AAIFF) e-commerce website, creating a seamless experience of
                  exploring different events and purchasing tickets in advance.
                </p>
                <div className="project__links">
                  <a
                    href="https://www.aaiff.org/"
                    target="_blank"
                    className="footer__links__item"
                    rel="noreferrer"
                  >
                    <GlobeLogo width="1.5rem" />
                  </a>
                </div>
              </div>
            </div>
            <div className="project">
              <Link to="/botanic-hub" className="project-image">
                <img
                  src={botanichub}
                  className="project-image__file"
                  alt="project thumbnail"
                />
              </Link>
              <div className="project-text">
                <Link to="/botanic-hub" className="project-text__link-ux">
                  <h4>Botanic Hub</h4>
                </Link>
                <ul className="tech">
                  <li className="tech__item">Full-stack Web Dev</li>
                  <li className="tech__item">UI Design</li>
                </ul>
                <p className="project-text__description">
                  Built a responsive web app that serves as an informational
                  resource for medicinal herbs and plants. It allows users to
                  select a specific health concern, and find plants that could
                  be used as treatment. If registered, the user can save any
                  plant to their profile.
                </p>
                <div className="project__links">
                  <a
                    href="https://github.com/mieko61/botanic-hub-frontend"
                    target="_blank"
                    className="footer__links__item"
                    rel="noreferrer"
                  >
                    <GithubLogo width="1.5rem" />
                  </a>
                  <a
                    href="https://botanic-hub.netlify.app"
                    target="_blank"
                    className="footer__links__item"
                    rel="noreferrer"
                  >
                    <GlobeLogo width="1.5rem" />
                  </a>
                </div>
              </div>
            </div>

            {/* <div className="project">
              <a
                className="project-image"
                href="https://github.com/malyesh/hydrate-or-dydrate"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={hydrate}
                  className="project-image__file"
                  alt="project thumbnail"
                />
              </a>
              <div className="project-text">
                <a
                  to="/hydrate"
                  className="project-text__link-ux"
                  href="https://github.com/malyesh/hydrate-or-dydrate"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h4>HYDRATE/DYDRATE</h4>
                </a>
                <div className="project__links">
                  <a
                    href="https://github.com/malyesh/hydrate-or-dydrate"
                    target="_blank"
                    className="footer__links__item"
                    rel="noreferrer"
                  >
                    <GithubLogo width="1.5rem" />
                  </a>
                </div>
                <p className="project-text__description">
                  Responsive web app where users can track their hydration (or
                  lack thereof) by updating their water and coffee intake levels
                  by cup. It displays a user's water and coffee intake so they
                  can see the disparity between the two levels, and hopefully
                  encourage more hydration.<br></br>
                  <strong>
                    {" "}
                    Developed in collaboration with a cohort partner.
                  </strong>
                </p>
                <ul className="tech">
                  <li className="tech__item">Full-stack Web Dev</li>
                  <li className="tech__item">UI Design</li>
                </ul>
              </div>
            </div> */}
            <div className="project">
              <Link to="/atelier-keki" className="project-image">
                <img
                  src={keki}
                  className="project-image__file"
                  alt="project thumbnail"
                />
              </Link>
              <div className="project-text">
                <Link to="/atelier-keki" className="project-text__link-ux">
                  <h4>Atelier Keki</h4>
                </Link>
                <ul className="tech">
                  <li className="tech__item">UX Research</li>
                  <li className="tech__item">UX Design</li>
                </ul>
                <p className="project-text__description">
                  Designed an iOS responsive website prototype for a small
                  business that allows customers to browse the menu and place
                  dessert orders online.
                </p>
              </div>
            </div>
            <div className="project">
              <Link to="/moviepop" className="project-image">
                <img
                  src={moviepop}
                  className="project-image__file"
                  alt="project thumbnail"
                />
              </Link>
              <div className="project-text">
                <Link to="/moviepop" className="project-text__link-ux">
                  <h4>MOVIEPOP</h4>
                </Link>
                <ul className="tech">
                  <li className="tech__item">UX Research</li>
                  <li className="tech__item">UX Design</li>
                </ul>
                <p className="project-text__description">
                  Designed a responsive design proposal that guarantees a smooth
                  movie theater experience - from tickets and seat selection to
                  snack purchase.
                </p>
              </div>
            </div>
          </article>
        </Element>
      </section>
    </main>
  );
};

export default Homepage;
