import ua1 from "../../assets/images/ua-1.jpeg";
import ua2 from "../../assets/images/ua-2.jpeg";
import ua3 from "../../assets/images/ua-3.jpeg";
import ua4 from "../../assets/images/ua-4.jpeg";
import GoToTop from "../../components/GoToTop/GoToTop";

let UnderArmour = () => {
  return (
    <main className="main-container">
      <section className="main projects">
        <span className="feature__title">
          <h1 className="feature__title__text">UNDER ARMOUR</h1>
          <div className="feature__title__line" />
        </span>
        <img src={ua1} className="apparel" />
        <img src={ua2} className="apparel" />
        <img src={ua3} className="apparel" />
        <img src={ua4} className="apparel" />
      </section>
      <GoToTop />
    </main>
  );
};

export default UnderArmour;
