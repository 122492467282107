import "./BackToTopButton.scss";
import React, { useEffect, useState } from "react";
import { FaCircleChevronUp } from "react-icons/fa6";

const GoTop = () => {
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div>
        {backToTopButton && (
          <button className="goTop" onClick={scrollUp}>
            <FaCircleChevronUp className="goTop__text" />
          </button>
        )}
      </div>
    </>
  );
};
export default GoTop;
