import process1 from "../../assets/images/process-1.jpeg";
import process2 from "../../assets/images/process-2.jpeg";
import process3 from "../../assets/images/process-3.jpeg";
import process4 from "../../assets/images/process-4.jpeg";
import process5 from "../../assets/images/process-5.jpeg";
import process6 from "../../assets/images/process-6.jpeg";
import process7 from "../../assets/images/process-7.jpeg";
import process8 from "../../assets/images/process-8.jpeg";
import GoToTop from "../../components/GoToTop/GoToTop";

let Process = () => {
  return (
    <main className="main-container">
      <section className="main">
        <span className="feature__title">
          <h1 className="feature__title__text">PROCESS</h1>
          <div className="feature__title__line" />
        </span>
        <img src={process1} className="apparel" />
        <img src={process2} className="apparel" />
        <img src={process3} className="apparel" />
        <img src={process4} className="apparel" />
        <img src={process5} className="apparel" />
        <img src={process6} className="apparel" />
        <img src={process7} className="apparel" />
        <img src={process8} className="apparel" />
      </section>
      <GoToTop />
    </main>
  );
};

export default Process;
