import Tdream1 from "../../assets/images/UO+KNITS-01.jpg";
import Tdream2 from "../../assets/images/UO+KNITS-02.jpg";
import Tdream3 from "../../assets/images/UO+KNITS-03.jpg";
import Tdream4 from "../../assets/images/UO+KNITS-04.jpg";
import Tdream5 from "../../assets/images/UO+KNITS-05.jpg";
import Tdream6 from "../../assets/images/UO+KNITS-06.jpg";
import Tdream7 from "../../assets/images/UO+KNITS-07.jpg";
import Tdream8 from "../../assets/images/UO+KNITS-08.jpg";
import Tdream9 from "../../assets/images/UO+KNITS-09.jpg";
import Tdream10 from "../../assets/images/UO+KNITS-10.jpg";
import Tdream11 from "../../assets/images/UO+KNITS-11.jpg";
import Tdream12 from "../../assets/images/UO+KNITS-12.jpg";
import GoToTop from "../../components/GoToTop/GoToTop";

let Tdream = () => {
  return (
    <main className="main-container">
      <section className="main">
        <span className="feature__title">
          <h1 className="feature__title__text">TRANSCENDENT DREAM</h1>
          <div className="feature__title__line" />
        </span>
        <img src={Tdream1} className="apparel" />
        <img src={Tdream2} className="apparel" />
        <img src={Tdream3} className="apparel" />
        <img src={Tdream4} className="apparel" />
        <img src={Tdream5} className="apparel" />
        <img src={Tdream6} className="apparel" />
        <img src={Tdream7} className="apparel" />
        <img src={Tdream8} className="apparel" />
        <img src={Tdream9} className="apparel" />
        <img src={Tdream10} className="apparel" />
        <img src={Tdream11} className="apparel" />
        <img src={Tdream12} className="apparel" />
      </section>
      <GoToTop />
    </main>
  );
};

export default Tdream;
