import "./AtelierKeki.scss";
import keki from "../../assets/images/keki-cover.png";
import kekiFeatures from "../../assets/images/keki-post-its.png";
import kekiUser from "../../assets/images/keki-user.png";
import kekiJourney from "../../assets/images/keki-journey.png";
import kekiSketch from "../../assets/images/keki-sketch.png";
import kekiSitemap from "../../assets/images/keki-sitemap.png";
import kekiWireframe1 from "../../assets/images/keki-wireframe1.png";
import kekiWireframe2 from "../../assets/images/keki-wireframe2.png";
import kekiMockup from "../../assets/images/keki-mockup.png";
import kekiLofiProto from "../../assets/images/keki-lofi-proto.png";
import kekiUsability from "../../assets/images/keki-usability.png";
import kekiMockups from "../../assets/images/keki-mockups.png";
import kekiHifi from "../../assets/images/keki-hifi-proto.png";
import kekiHifiProto from "../../assets/images/keki-fidi-flow.png";
import kekiFinal from "../../assets/images/keki-final.png";
import kekiNext from "../../assets/images/keki-next.png";
import GoToTop from "../../components/GoToTop/GoToTop";

let AtelierKeki = () => {
  return (
    <main className="main-container">
      <section className="main projects">
        <span className="feature__title">
          <h1 className="feature__title__text">ATELIER KEKI</h1>
          <div className="feature__title__line" />
        </span>{" "}
        <img src={keki} className="projects__hero" alt="hero" />
        <a
          href="https://www.figma.com/file/iXOKsx8zGZMlSAvTjN5zB9/Atelier-Amai?node-id=314%3A3237&t=Ba5CBr5yLDtnq4Be-1"
          target="_blank"
          rel="noreferrer"
          className="keki-category__link"
        >
          View Atelier Keki prototype for desktop
        </a>
        <h5 className="projects__subheader">Project Overview</h5>
        <article className="keki-client">
          <h4 className="keki-client__title">CLIENT: ATELIER KEKI</h4>
          <p className="keki-category__text">
            Starting in December 2022, I worked with Atelier Keki, a small
            dessert business based in Lima, Peru. Its menu includes artisanal
            desserts inspired by French techniques and Japanese culture. The
            brand needed a website that was easy to navigate and visually
            engaging and that would allow customers to place online orders. In 3
            months, I delivered a complete set of responsive website design and
            design system.
          </p>
        </article>
        <article className="keki-statement">
          <div className="keki-statement__subcat">
            <h4 className="keki-statement__subcat__title">PROBLEM</h4>
            <p className="keki-category__text">
              At the moment, users place their orders via Whatsapp. There is a
              lot of back and forth between the customer and the business that
              could be alleviated through a more streamlined process.
            </p>
          </div>
          <div className="keki-statement__subcat">
            <h4 className="keki-statement__subcat__title">GOAL</h4>
            <p className="keki-category__text">
              Design a website that would allow customers to visualize the
              desserts, read detailed information about the product, and find
              answers to the most commonly asked questions. Most importantly,
              they would be able to place orders on their own.
            </p>
          </div>
        </article>
        <article className="keki-details">
          <div className="keki-details__subcat">
            <h4 className="keki-details__subcat__title">MY ROLE</h4>
            <p className="keki-details__subcat__text">
              UX designer from conception to delivery
            </p>
          </div>
          <div className="keki-details__tools">
            <h4 className="keki-details__subcat__title">TOOLS</h4>
            <ul className="keki-list">
              <li>Figma</li>
              <li>Google Suite</li>
              <li>Octopus</li>
              <li>Miro</li>
            </ul>
          </div>
          <div className="keki-details__subcat">
            <h4 className="keki-details__subcat__title">PROJECT DURATION</h4>
            <p className="keki-category__text">December 2022 - March 2023</p>
          </div>
        </article>
        <h5 className="projects__subheader">Understanding the User</h5>
        <article className="keki-research">
          <h4 className="keki-research__title">USER RESEARCH</h4>
          <div className="keki-research__content">
            <p className="keki-research__summary">
              Based on Atelier Keki's business model, I created a set of
              questions for potential users and focused on collecting
              qualitative data. I interviewed 6 individuals and these are the
              findings I took note of based on the answers received.
            </p>
            <ul className="keki-list">
              <li>
                Instagram is the user’s main source, so having a{" "}
                <strong>strong social media presence</strong> is key.
              </li>
              <li>
                <strong>Mobile phone</strong> is the preferred device for all
                users.
              </li>
              <li>
                What users look for on a business website are:{" "}
                <strong>Hours & location, Product range</strong> - description,
                ingredients, and photos.
              </li>
              <li>
                Being able to order the product online is important to users.
              </li>
              <li>
                Websites need to be <strong>simple, easy to use,</strong> and
                helpful. Users lose interest if websites are too busy or don’t
                allow them to fulfill their tasks.
              </li>
              <li>
                Users get frustrated with unresponsive websites and commonly
                have trouble with photos being too large and with having to
                scroll for too long when using a mobile device.
              </li>
            </ul>
          </div>
          <h4 className="keki-research__title">COMPETITIVE ANALYSIS</h4>
          <p className="keki-research__analysis">
            I conducted a competitive audit that allowed me to better understand
            the market our product would be entering. Two of the three direct
            competitors are powered by the same e-commerce platform, so their
            websites look very similar, almost generic. They are not fully
            responsive - some information gets lost on the mobile version, and
            scale and proportion could be re-evaluated. There is an opportunity
            to create a product that adapts better to the customer's experience.
          </p>
          <a
            href="https://docs.google.com/spreadsheets/d/1Ub0U8L98izJdINFdE4CrixIjGYVS0vjNxQfC6xU3Y2E/edit?usp=sharing"
            target="_blank"
            className="keki-research__link"
            rel="noreferrer"
          >
            View full Competitive Audit Report
          </a>
          <p className="keki-research__features">
            There are <strong>key features</strong> that will be important to
            keep in mind and gaps that could definitely be filled in order to
            provide the user with a complete and informative product. These are
            the main points to consider:
          </p>
          <img
            src={kekiFeatures}
            className="keki-research__image"
            alt="features"
          />
        </article>
        <article className="keki-category">
          <h4 className="keki-category__title">USER PERSONA</h4>
          <p className="keki-category__text">
            Amanda is a busy account manager who needs an informative yet easy
            source where she could order desserts from because she finds joy in
            treating herself.
          </p>
          <img
            src={kekiUser}
            className="keki-category__image__padding"
            alt="user persona"
          ></img>
        </article>
        <article className="keki-category">
          <h4 className="keki-category__title">USER JOURNEY</h4>
          <p className="keki-category__text">
            Creating a user journey map allowed me to sympathize with the user’s
            emotions and identify potential pain points that could be addressed
            while developing Atelier Keki's website.
          </p>
          <img
            src={kekiJourney}
            className="keki-category__image__padding"
            alt="user journey"
          ></img>
        </article>
        <article className="keki-ideation">
          <div className="keki-ideation__info">
            <h4 className="keki-ideation__info__title">IDEATION</h4>
            <p className="keki-ideation__info__text">
              I did a quick ideation exercise to visualize a website that would
              address the gaps identified during the research stage. I focused
              on creating a visually appealing and straightforward user flow.
            </p>
          </div>
          <img
            src={kekiSketch}
            className="keki-ideation__image"
            alt="app sketch"
          />
        </article>
        <h5 className="projects__subheader">Starting the Design</h5>
        <article className="keki-category">
          <h4 className="keki-category__title">SITEMAP</h4>
          <p className="keki-category__text">
            Guided by the designs brainstormed during the ideation process, I
            created a sitemap to visualize the organization and content of each
            page.
          </p>
          <img
            src={kekiSitemap}
            className="keki-category__image__padding"
            alt="sitemap"
          ></img>
        </article>
        <article className="keki-category">
          <h4 className="keki-category__title">
            WIREFRAMES - FROM PAPER TO DIGITAL
          </h4>
          <p className="keki-category__text">
            After brainstorming some paper wireframes, I created the initial
            designs for the website. These designs focused on showcasing Atelier
            Keki’s beautiful products and providing the user with all the
            necessary information to successfully place an order online.
            According to the data gathered from the initial research, mobile is
            the most popular device among our users, so I decided to start with
            that version.
          </p>
          <img
            src={kekiWireframe1}
            className="keki-category__image"
            alt="wireframe"
          ></img>
          <img
            src={kekiWireframe2}
            className="keki-category__image"
            alt="wireframe"
          ></img>
          <img
            src={kekiMockup}
            className="keki-category__image"
            alt="mockup"
          ></img>
          <p className="keki-category__text">
            To prepare for usability testing, I created a low-fidelity prototype
            that connected the user flow of placing a dessert order online.
          </p>
          <a
            href="https://www.figma.com/proto/iXOKsx8zGZMlSAvTjN5zB9/Atelier-Amai?node-id=14-614&page-id=0%3A1&scaling=scale-down&show-proto-sidebar=1&starting-point-node-id=14%3A614&viewport=285%2C421%2C0.25"
            target="_blank"
            className="keki-category__link"
            rel="noreferrer"
          >
            View Atelier Keki low-fidelity prototype
          </a>
          <img
            src={kekiLofiProto}
            className="keki-category__image__padding"
            alt="lo fi proto"
          ></img>
        </article>
        <article className="keki-category">
          <h4 className="keki-category__title">USABILITY STUDY</h4>
          <p className="keki-category__text">
            I conducted a round of usability studies to test the efficiency of
            the main user flow and overall IA before moving on to the next step.
          </p>
          <img
            src={kekiUsability}
            className="keki-category__image"
            alt="usability study"
          ></img>
        </article>
        <h5 className="projects__subheader">Refining the Design</h5>
        <h4 className="keki-category__title">MOCKUPS</h4>
        <img
          src={kekiMockups}
          className="keki-category__image"
          alt="mockups"
        ></img>
        <img
          src={kekiHifi}
          className="keki-category__image"
          alt="hi fi proto"
        ></img>
        <img
          src={kekiHifiProto}
          className="keki-category__image__padding"
          alt="hi fi proto"
        ></img>
        <h4 className="keki-category__title">RESPONSIVE DESIGN</h4>
        <p className="keki-category__text">
          The designs for screen size variations included mobile, tablet, and
          desktop. I optimized the design to fit specific user needs of each
          device and screen size.
        </p>
        <img
          src={kekiFinal}
          className="keki-category__image__padding"
          alt="final design"
        ></img>
        <article className="keki-access">
          <h4 className="keki-access__title">ACCESSIBILITY CONSIDERATIONS</h4>
          <ol className="keki-access__list">
            <li>
              I used different text sizes for headings consistently throughout
              the website for <strong>clear visual hierarchy</strong>.
            </li>
            <li>
              Commonly known icons were used throughout for visual guidance.
              Text was added next to <strong> icons </strong>to make sure that
              it could be read by screen readers.
            </li>
            <li>
              I tested the color combinations used to ensure that the
              <strong> contrast ratio met WCAG guidelines </strong>for
              accessible design using WebAIM's Contrast Checker tool.
            </li>
          </ol>
        </article>
        <h5 className="projects__subheader">Going Forward</h5>
        <article className="keki-forward">
          <div className="keki-category keki-forward__content">
            <h4 className="keki-category__title">IMPACT</h4>
            <p className="keki-category__text__impact">
              Users agreed that having a single source where they could browse
              through the products on detail and seamlessly place their orders
              made their experience easier and less tedious.
            </p>
          </div>
          <div className="keki-category  keki-forward__content">
            <h4 className="keki-category__title">WHAT I LEARNED</h4>
            <p className="keki-category__text">
              Although I had a clear task from the get-go, going through the
              design process and having various rounds of testing allowed me to
              address small details that would be useful to the users when going
              through the main flow. One user shared:
            </p>
            <p className="keki-category__quote">
              “In terms of design, I really like it (the website). It looks
              clean and that makes the photos of the desserts the main focus.”
            </p>
          </div>
        </article>
        <img
          src={kekiNext}
          className="keki-category__image"
          alt="next steps"
        ></img>
      </section>
      <GoToTop />
    </main>
  );
};

export default AtelierKeki;
