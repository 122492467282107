import botanichub from "../../assets/images/botanic_hub_mobile-view.svg";
import hydrate from "../../assets/images/hydrate.png";
import keki from "../../assets/images/keki-cover.png";
import moviepop from "../../assets/images/moviepop-cover.jpeg";
import asiancinevision from "../../assets/images/AAIFF_main_thumbnail.png";
import GithubLogo from "../../components/GithubLogo/GithubLogo";
import GlobeLogo from "../../components/GlobeLogo/GlobeLogo";
import "./WebApps.scss";
import { Link } from "react-router-dom";

let Projects = () => {
  return (
    <main className="main-container">
      <section className="main projects">
        <span className="feature__title">
          <h1 className="feature__title__text">MY WORK</h1>
          <div className="feature__title__line" />
        </span>{" "}
        <article className="projects-container">
          <div className="project">
            <Link to="/asian-cinevision" className="project-image">
              <img
                src={asiancinevision}
                className="project-image__file"
                alt="project thumbnail"
              />
            </Link>
            <div className="project-text">
              <Link to="/asian-cinevision" className="project-text__link-ux">
                <h4>Asian Cinevision</h4>
              </Link>
              <div className="project__links">
                <a
                  href="https://www.aaiff.org/"
                  target="_blank"
                  className="footer__links__item"
                  rel="noreferrer"
                >
                  <GlobeLogo width="1.5rem" />
                </a>
              </div>
              <p className="project-text__description">
                Redesigning the 2024 Asian American International Film Festival
                (AAIFF) e-commerce website, creating a seamless experience of
                exploring different events and purchasing tickets in advance.
              </p>
              <ul className="tech">
                <li className="tech__item">UX Research</li>
                <li className="tech__item">UX Design</li>
                <li className="tech__item">Front-End Web Dev</li>
              </ul>
            </div>
          </div>
          <div className="project">
            <Link to="/botanic-hub" className="project-image">
              <img
                src={botanichub}
                className="project-image__file"
                alt="project thumbnail"
              />
            </Link>
            <div className="project-text">
              <Link to="/botanic-hub" className="project-text__link-ux">
                <h4>Botanic Hub</h4>
              </Link>
              <div className="project__links">
                <a
                  href="https://github.com/mieko61/botanic-hub-frontend"
                  target="_blank"
                  className="footer__links__item"
                  rel="noreferrer"
                >
                  <GithubLogo width="1.5rem" />
                </a>
                <a
                  href="https://botanic-hub.netlify.app"
                  target="_blank"
                  className="footer__links__item"
                  rel="noreferrer"
                >
                  <GlobeLogo width="1.5rem" />
                </a>
              </div>
              <p className="project-text__description">
                Responsive web app that serves as an informational resource for
                medicinal herbs and plants. It allows users to select a specific
                health concern, and find plants that could be used as treatment.
                If registered, the user can save any plant to their profile.
              </p>
              <ul className="tech">
                <li className="tech__item">Full-stack Web Dev</li>
                <li className="tech__item">UI Design</li>
              </ul>
            </div>
          </div>

          <div className="project">
            <a
              className="project-image"
              href="https://github.com/malyesh/hydrate-or-dydrate"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={hydrate}
                className="project-image__file"
                alt="project thumbnail"
              />
            </a>
            <div className="project-text">
              <a
                to="/hydrate"
                className="project-text__link-ux"
                href="https://github.com/malyesh/hydrate-or-dydrate"
                target="_blank"
                rel="noreferrer"
              >
                <h4>HYDRATE/DYDRATE</h4>
              </a>
              <div className="project__links">
                <a
                  href="https://github.com/malyesh/hydrate-or-dydrate"
                  target="_blank"
                  className="footer__links__item"
                  rel="noreferrer"
                >
                  <GithubLogo width="1.5rem" />
                </a>
              </div>
              <p className="project-text__description">
                Responsive web app where users can track their hydration (or
                lack thereof) by updating their water and coffee intake levels
                by cup. It displays a user's water and coffee intake so they can
                see the disparity between the two levels, and hopefully
                encourage more hydration.<br></br>
                <strong>
                  {" "}
                  Developed in collaboration with a cohort partner.
                </strong>
              </p>
              <ul className="tech">
                <li className="tech__item">Full-stack Web Dev</li>
                <li className="tech__item">UI Design</li>
              </ul>
            </div>
          </div>
          <div className="project">
            <Link to="/atelier-keki" className="project-image">
              <img
                src={keki}
                className="project-image__file"
                alt="project thumbnail"
              />
            </Link>
            <div className="project-text">
              <Link to="/atelier-keki" className="project-text__link-ux">
                <h4>Atelier Keki</h4>
              </Link>
              <p className="project-text__description">
                iOS responsive website prototype for a small business that
                allows customers to browse the menu and place dessert orders
                online.
              </p>
              <ul className="tech">
                <li className="tech__item">UX Research</li>
                <li className="tech__item">UX Design</li>
              </ul>
            </div>
          </div>
          <div className="project">
            <Link to="/moviepop" className="project-image">
              <img
                src={moviepop}
                className="project-image__file"
                alt="project thumbnail"
              />
            </Link>
            <div className="project-text">
              <Link to="/moviepop" className="project-text__link-ux">
                <h4>MOVIEPOP</h4>
              </Link>
              <p className="project-text__description">
                Responsive design proposal that guarantees a smooth movie
                theater experience - from tickets and seat selection to snack
                purchase.
              </p>
              <ul className="tech">
                <li className="tech__item">UX Research</li>
                <li className="tech__item">UX Design</li>
              </ul>
            </div>
          </div>
        </article>
      </section>
    </main>
  );
};

export default Projects;
