import "./Moviepop.scss";
import moviepop from "../../assets/images/moviepop-cover.jpeg";
import moviepopCompetitive from "../../assets/images/moviepop-competitive.png";
import moviepopUser1 from "../../assets/images/moviepop-user1.png";
import moviepopUser2 from "../../assets/images/moviepop-user2.png";
import moviepopUser3 from "../../assets/images/moviepop-user3.png";
import moviepopUser4 from "../../assets/images/moviepop-user4.png";
import moviepopUser5 from "../../assets/images/moviepop-user5.png";
import moviepopPersona from "../../assets/images/moviepop-user.png";
import moviepopJourney from "../../assets/images/moviepop-journey.png";
import moviepopSitemap from "../../assets/images/moviepop-sitemap.png";
import moviepopSketches from "../../assets/images/moviepop-sketches.png";
import moviepopWireframes from "../../assets/images/moviepop-wireframes.jpeg";
import moviepopParameters from "../../assets/images/moviepop-parameters.jpeg";
import moviepopFindings from "../../assets/images/moviepop-finding.jpeg";
import moviepopUsability1 from "../../assets/images/moviepop-refine.jpeg";
import moviepopUsability2 from "../../assets/images/moviepop-refine2.jpeg";
import moviepopMobile from "../../assets/images/moviepop-hifi-mobile.jpeg";
import moviepopDesktop from "../../assets/images/moviepop-hifi-desktop.jpeg";
import moviepopAccess from "../../assets/images/moviepop-access.jpeg";
import moviepopNext from "../../assets/images/moviepop-next.jpeg";
import GoToTop from "../../components/GoToTop/GoToTop";

let Moviepop = () => {
  return (
    <main className="main-container">
      <section className="main projects">
        <span className="feature__title">
          <h1 className="feature__title__text">MOVIEPOP</h1>
          <div className="feature__title__line" />
        </span>{" "}
        <img src={moviepop} className="projects__hero" alt="hero" />
        <a
          href="https://www.figma.com/file/iXOKsx8zGZMlSAvTjN5zB9/Atelier-Amai?node-id=314%3A3237&t=Ba5CBr5yLDtnq4Be-1https://www.figma.com/file/iXOKsx8zGZMlSAvTjN5zB9/Atelier-Amai?node-id=314%3A3237&t=Ba5CBr5yLDtnq4Be-1"
          target="_blank"
          rel="noreferrer"
          className="moviepop-category__link"
        >
          View Moviepop prototype
        </a>
        <h5 className="projects__subheader">Project Overview</h5>
        <article className="moviepop-client">
          <h4 className="moviepop-client__title">PRODUCT</h4>
          <p>
            Moviepop is a modern movie theater company with three locations
            across New York City, each located in a different borough -
            Manhattan, Brooklyn, and Queens.
          </p>
        </article>
        <article className="moviepop-statement">
          <div className="moviepop-statement__subcat">
            <h4 className="moviepop-statement__subcat__title">PROBLEM</h4>
            <p>
              Moviegoers would like to book their tickets, select their seats,
              and purchase snacks in advance to save time and effort.
            </p>
          </div>
          <div className="moviepop-statement__subcat">
            <h4 className="moviepop-statement__subcat__title">GOAL</h4>
            <p>
              Create a more pleasant movie theater experience by allowing
              moviegoers to complete all of these tasks ahead of time.
            </p>
          </div>
        </article>
        <article className="moviepop-details">
          <div className="moviepop-details__subcat">
            <h4 className="moviepop-details__subcat__title">MY ROLE</h4>
            <p className="moviepop-details__subcat__text">
              UX designer from conception to delivery
            </p>
          </div>
          <div className="moviepop-details__tools">
            <h4 className="moviepop-details__subcat__title">TOOLS</h4>
            <ul className="keki-list">
              <li>Adobe XD</li>
              <li>Google Suite</li>
            </ul>
          </div>
          <div className="moviepop-details__subcat">
            <h4 className="moviepop-details__subcat__title">
              PROJECT DURATION
            </h4>
            <p className="moviepop-details__subcat__text">
              July 2022 - August 2022
            </p>
          </div>
        </article>
        <h5 className="projects__subheader">Understanding the User</h5>
        <article className="moviepop-research">
          <h4 className="moviepop-research__title">COMPETITIVE ANALYSIS</h4>
          <div className="moviepop-research__content">
            <p className="moviepop-research__summary">
              I conducted a competitive audit including both direct and indirect
              competitors to find gaps in the market that Moviepop could fill.
            </p>
            <img
              src={moviepopCompetitive}
              className="moviepop-research__analysis-image"
              alt="features"
            />
          </div>
          <h4 className="moviepop-research__title">USER INTERVIEWS</h4>
          <p className="moviepop-research__analysis">
            I conducted user interviews and then synthesized the information
            into empathy maps to have a better understanding of the users'
            thoughts. I found out that overall, users have a positive experience
            when going to the movies.<br></br>
            <br></br> They value having the option to buy their tickets in
            advance because this allows them to plan their outing and prevents
            them from having to show up at the theater super early. It's also a
            big incentive when they're able to earn points and get rewarded. So,
            having a rewards system that's easy to access is key.
          </p>
          <div className="moviepop-research__image__container">
            <img
              src={moviepopUser1}
              className="moviepop-research__image"
              alt="user1"
            />
            <img
              src={moviepopUser2}
              className="moviepop-research__image"
              alt="user2"
            />
            <img
              src={moviepopUser3}
              className="moviepop-research__image"
              alt="user3"
            />
            <img
              src={moviepopUser4}
              className="moviepop-research__image"
              alt="user4"
            />
            <img
              src={moviepopUser5}
              className="moviepop-research__image"
              alt="user5"
            />
          </div>
          <h4 className="moviepop-research__title">USER PAIN POINTS</h4>
          <article className="moviepop-details">
            <div className="moviepop-details__subcat">
              <h5 className="moviepop-details__subcat__title">TIME</h5>
              <p className="moviepop-details__subcat__text">
                Buying tickets and snacks at the movie theater entails having to
                show up in advance and wait in line
              </p>
            </div>
            <div className="moviepop-details__tools">
              <h5 className="moviepop-details__subcat__title">NAVIGATION</h5>
              <p className="moviepop-details__subcat__text">
                Number of rewards points earned can be hard to find on some
                websites
              </p>
            </div>
            <div className="moviepop-details__subcat">
              <h5 className="moviepop-details__subcat__title">
                MISSING FEATURE
              </h5>
              <p className="moviepop-details__subcat__text">
                Having the option to share the tickets with other people in
                their party would be helpful
              </p>
            </div>
          </article>
        </article>
        <article className="moviepop-category">
          <h4 className="moviepop-category__title__persona">USER PERSONA</h4>
          <p className="moviepop-category__text">
            Claire is a busy media manager that loves superhero movies who needs
            to book tickets and snacks in advance because this saves them time
            when going to the theater.
          </p>
          <img
            src={moviepopPersona}
            className="moviepop-category__image__padding"
            alt="user persona"
          ></img>
        </article>
        <article className="moviepop-category">
          <h4 className="moviepop-category__title">USER JOURNEY</h4>
          <p className="moviepop-category__text">
            I created a user journey map for Claire's experience in order to
            identify possible pain points and potential features that could
            improve their experience.
          </p>
          <img
            src={moviepopJourney}
            className="moviepop-category__image"
            alt="user journey"
          ></img>
        </article>
        <h5 className="projects__subheader">Starting the Design</h5>
        <article className="moviepop-category">
          <h4 className="moviepop-category__title">SITEMAP</h4>
          <p className="moviepop-category__text">
            I created a sitemap by choosing and organizing all the information
            that's relevant to the user and that would be needed for a
            streamlined user flow. This will help me make sure specific
            categories are accessible for easy navigation.
          </p>
          <img
            src={moviepopSitemap}
            className="moviepop-category__image__padding"
            alt="sitemap"
          ></img>
        </article>
        <article className="moviepop-category">
          <h4 className="moviepop-category__title">PAPER WIREFRAMES</h4>
          <p className="moviepop-category__text">
            I sketched out paper wireframes for each screen of the website,
            keeping key features and the main user flow in mind.<br></br>
            <br></br>
            Considering that users could access the Moviepop website from
            different types of devices, I sketched out some additional screens
            for mobile and tablet in order to have a site that would be fully
            responsive.
          </p>
          <img
            src={moviepopSketches}
            className="moviepop-category__image__padding"
            alt="wireframe"
          ></img>
        </article>
        <article className="moviepop-category">
          <h4 className="moviepop-category__title">DIGITAL WIREFRAMES</h4>
          <p className="moviepop-category__text">
            Moving on to digital wireframes, I prioritized the user's goal to
            easily book tickets online by playing around with scale and
            proportion, emphasizing the most important elements of the flow.
            <br></br>
            <br></br> I then created responsive mobile wireframes for some of
            the screens. This allows for an optimized user experience across
            different devices. I made sure to keep all the information present
            but worked on different sizes and layouts that would make navigation
            easier on a smaller screen.
          </p>
          <img
            src={moviepopWireframes}
            className="moviepop-category__image__padding"
            alt="wireframe"
          ></img>
        </article>
        <article className="moviepop-category">
          <h4 className="moviepop-category__title">USABILITY STUDY</h4>
          <p className="moviepop-category__text">
            Next, I executed a usability study of the website to test the main
            user flow and to identify any additional features that might be
            missing. By synthesizing the collected data into affinity maps , I
            was able to identify parts of the flow where the user was getting
            stuck, thus needing improvement.
          </p>
          <img
            src={moviepopParameters}
            className="moviepop-category__image"
            alt="usability study"
          ></img>
          <img
            src={moviepopFindings}
            className="moviepop-category__image"
            alt="usability study"
          ></img>
        </article>
        <h5 className="projects__subheader">Refining the Design</h5>
        <h4 className="moviepop-category__title">MOCKUPS</h4>
        <p className="moviepop-category__text">
          Based on the feedback received from the usability studies, I made some
          updates to the mockup that would improve the user experience. I
          relooked at scale and proportion and placed the 'Seat Selection'
          section next to the 'Showtimes' to make it easier for the user to
          understand the task to be completed on the screen. This way, users are
          also able to see the seats available for each showtime without having
          to scroll down.
        </p>
        <img
          src={moviepopUsability1}
          className="moviepop-category__image"
          alt="mockups"
        ></img>
        <p className="moviepop-category__text">
          Also, users worried about the session expiring while completing their
          purchase, thus losing their selected seats, so I added a timer to the
          'Checkout' page. This gives visibility to users over the time they
          have to successfully book their reservation.
        </p>
        <img
          src={moviepopUsability2}
          className="moviepop-category__image__padding"
          alt="hi fi proto"
        ></img>
        <h4 className="moviepop-category__title">SCREEN SIZE VARIATIONS</h4>
        <p className="moviepop-category__text">
          I worked on mockups for the mobile version of the website based on the
          wireframes created earlier in the process. This would ensure that the
          website could adapt to a smaller screen in a way that would optimize
          the user's experience.
        </p>
        <img
          src={moviepopMobile}
          className="moviepop-category__image__padding"
          alt="mockups"
        ></img>
        <h4 className="moviepop-category__title">HIGH FIDELITY PROTOTYPE</h4>
        <p className="moviepop-category__text">
          My high-fidelity prototype included the design changes made following
          the usability studies and an alternative user flow suggested by a team
          member.
        </p>
        <img
          src={moviepopDesktop}
          className="moviepop-category__image__padding"
          alt="mockups"
        ></img>
        <article className="moviepop-access">
          <h4 className="moviepop-access__title">
            ACCESSIBILITY CONSIDERATIONS
          </h4>
          <img
            src={moviepopAccess}
            className="moviepop-category__image"
            alt="mockups"
          ></img>
        </article>
        <h5 className="projects__subheader">Going Forward</h5>
        <article className="moviepop-forward">
          <div className="moviepop-category moviepop-forward__content">
            <h4 className="moviepop-category__title">IMPACT</h4>
            <p className="moviepop-category__text__impact">
              The website design was easy to use and met the user's needs.
              Having features such as being able to add snacks to their order
              and earning rewards points made for a positive and rewarding user
              experience. One quote from user feedback:
            </p>
            <p className="moviepop-category__quote">
              “I think it makes sense, it's pretty intuitive.”
            </p>
          </div>
          <div className="moviepop-category  moviepop-forward__content">
            <h4 className="moviepop-category__title">WHAT I LEARNED</h4>
            <p className="moviepop-category__text">
              I learned that user testing is crucial when developing a website
              because it allows you to test your designs and often reconsider
              choices. It's important to always listen and be open to feedback
              and suggestions to create a product that genuinely solves users'
              pain points.
            </p>
          </div>
        </article>
        <img
          src={moviepopNext}
          className="moviepop-category__image"
          alt="next steps"
        ></img>
      </section>
      <GoToTop />
    </main>
  );
};

export default Moviepop;
